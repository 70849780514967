import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LazyLoad } from 'acq-components/lib/services/performance/LazyLoad/LazyLoad';
import { sendDTMCustomEvent } from 'service_modules/models/dtm-tagging';
import { hasPznDataArrived } from 'src/universal/redux/pznData';

const ChildToTrack = ({
  children = null,
  scrollDtm,
  callBackFunction,
  timeoutVal
}) => {
  useEffect(() => {
    if (scrollDtm) {
      /*
      why the setTimeout here?
      Because the next line will trigger dispatch on the global dtmEvents obj which may
      cause dispatching conflict when CSR rendering happens on any page containing this component.
      */
      setTimeout(() => sendDTMCustomEvent(scrollDtm), timeoutVal);
    }
    if (typeof callBackFunction === 'function') {
      callBackFunction();
    }
  }, []);

  return children;
};

const LazyScrollMetricTracker = ({
  children = null,
  offset = 0,
  scrollDtm,
  callBackFunction,
  timeoutVal = 0,
  pznArrived,
  startTrackingAfterPznArrival,
  height
}) => {
  /*
  Why adding the optional startTrackingAfterPznArrival prop?
  A given target component might falsely trigger the impression event upon initial page load when its
  preceeding components not being rendered immediately while waiting for pzn data.
  This 'startTrackingAfterPznArrival' prop, when set to true, will avoid this "false impression event".  
  */
  const [pznWaitIsOver, setPznWaitIsOver] = useState(false);
  useEffect(
    () => {
      if (startTrackingAfterPznArrival && pznArrived) {
        setTimeout(() => setPznWaitIsOver(true), 2000);
      }
    },
    [pznArrived]
  );

  if (startTrackingAfterPznArrival && !pznWaitIsOver) {
    return null;
  }

  return (
    <LazyLoad offset={offset} height={height}>
      <ChildToTrack
        scrollDtm={scrollDtm}
        callBackFunction={callBackFunction}
        timeoutVal={timeoutVal}
      >
        {children}
      </ChildToTrack>
    </LazyLoad>
  );
};

const mapStateToProps = state => ({
  pznArrived: hasPznDataArrived(state)
});

export const forceCheck = LazyLoad.forceCheck;

export default connect(mapStateToProps)(React.memo(LazyScrollMetricTracker));
