import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import { setPznReqEep } from 'src/universal/utils/orchestraCmPznExtEvent';

export const requestPznAnalytics = async ({
  pageId,
  pznData = [],
  pznAttributes = {},
  eepUsed = '',
  pznCardId = '',
  pmcCode = '',
  isDcfExtEvent = '',
  isDcfClickEvent = '',
  includeKabbage = '',
  isKabbageClickEvent = '',
  needCmIndicator = false
}) => {
  /*
  US3495698: CM-PZN-EXT must be handled at page/component level. so we skip the call.
  Details listed here: src/universal/utils/orchestraCmPznExtEvent.js 
  */
  if (needCmIndicator) {
    setPznReqEep(eepUsed);
    return;
  }

  let shouldCallPznExt = true;
  let updatedEEP = eepUsed;
  let updatedPmcCode = pmcCode;

  if (Array.isArray(pznData)) {
    const foundCardThatDoesNotNeedExtCall = pznData.some(
      card => !!card.offSiteEEP || !!card.preQual
    );
    shouldCallPznExt = !foundCardThatDoesNotNeedExtCall;
  }

  if (shouldCallPznExt || !!isDcfClickEvent || isDcfExtEvent) {
    const singleCardObj = pznData.find(card => card.isPrimaryCard) || pznData[0];

    if (singleCardObj) {
      updatedPmcCode = singleCardObj.pmcCode;
      if (singleCardObj.eep) {
        updatedEEP = singleCardObj.eep;
      }
    }

    const currentUrlParam = `&currentUrl=${window?.location?.pathname}`;
    const requestUrl = `open/content/pznExtn/${pageId}/${updatedEEP}${
      pznCardId
        ? `${pznCardId}?pmcCode=${updatedPmcCode}&pznReqFlowId=${pznAttributes.pznReqFlowId ||
            ''}&isDcfExtEvent=${isDcfExtEvent}&isDcfClickEvent=${isDcfClickEvent}&includeKabbage=${includeKabbage}&isKabbageClickEvent=${isKabbageClickEvent}${currentUrlParam}`
        : `?isDcfExtEvent=${isDcfExtEvent}&isDcfClickEvent=${isDcfClickEvent}&pznReqFlowId=${
            pznAttributes.pznReqFlowId
          }&includeKabbage=${includeKabbage}&isKabbageClickEvent=${isKabbageClickEvent}${currentUrlParam}`
    }`;
    await ajax.get(requestUrl, { requestName: 'app-request-pzn-ext' });
  }
};
