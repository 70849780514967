/* eslint-disable max-statements */
import React, { useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import get from 'lodash.get';

import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import SetCardMemberClassName from 'src/universal/components/CardMember/SetCardMemberClassName';
import OfferExpiryModal from 'src/universal/components/PZN/OfferExpiryModal';
import { dispatchSetCardMemberDataAction } from 'src/universal/redux/cardMemberInfo';
import { dispatchSetPznDataAction } from 'src/universal/redux/pznData';
import { getPznParams, determineCmIndicator } from './getPznParams';
import { needToFirePznExt } from 'src/universal/components/PZN/FetchData/pznExtHelper';
import { hasPznDataArrived } from 'src/universal/redux/pznData';
import { dispatchUpdateFromSessStore } from 'src/universal/redux/cardColors';
import {
  showCmUpgrade,
  getPznCmMockDataParam
} from 'src/universal/utils/orchestraUtils';
import { requestPznAnalytics } from './requestPznAnalytics';
import {
  dispatchSetPznError,
  getHasLegacyPznRequestDispatched
} from 'src/universal/redux/pznData.js';
import { determinePznEEP } from 'src/universal/utils/getEepForPznRequest';
import { pznPageIds } from 'src/universal/components/PZN/config';
import { getVacPathParts } from 'src/universal/utils/vacRouterPathHelper';
import {
  setOfferExpired,
  getOfferExpired
} from 'src/universal/components/PZN/OfferExpiryModal/expiryPersistence';

const FetchPznData = ({
  pageId = 'open_vac',
  cardShortName,
  pmcCode,
  routeEEP,
  globalData = {},
  setCardMemberData,
  setPznData,
  setPznErrInfo,
  usePZN = false,
  useCM = true,
  shouldRenderOfferExpiry,
  hasLegacyPznRequestDispatched,
  hasPznDataArrived,
  triggerColorChoicesUpdate
}) => {
  const hasRequestBeenMade = useRef(false);
  useLayoutEffect(
    () => {
      if (
        !hasPznDataArrived &&
        !hasLegacyPznRequestDispatched &&
        !hasRequestBeenMade.current
      ) {
        const eepForPzn = determinePznEEP(globalData, routeEEP);
        const fetchPznData = async () => {
          hasRequestBeenMade.current = true;
          try {
            let pznParams = {};
            let EEP = '';
            if (usePZN) {
              [pznParams, EEP] = await getPznParams(eepForPzn);
            }
            const urlParams = queryString.stringify({
              usePZN,
              useCM,
              ...pznParams
            });
            const showCmUpgradeFlag = showCmUpgrade() ? '&showCmUpgrade=true' : '';
            const cmMockDataParam = getPznCmMockDataParam();
            const useCmMockPgDataParam = cmMockDataParam
              ? `&${cmMockDataParam}`
              : '';
            const pznCardId = cardShortName ? `/${cardShortName}` : '';

            let offerExpiredParam = '';
            if (getOfferExpired()) {
              offerExpiredParam = `&clientOfferExpired=y`;
              setOfferExpired(false);
            }

            // Adding this to debug PZN requests with invalid Page ID
            const currentUrlParam = `&currentUrl=${window?.location?.pathname}`;

            const requestUrl = `open/content/pzn/${pageId}${EEP}${pznCardId}?${urlParams}${showCmUpgradeFlag}${useCmMockPgDataParam}${offerExpiredParam}${currentUrlParam}`;
            const {
              data: {
                pznData = [],
                pznAttributes = {},
                cmData: cardMemberData = {},
                dcf = {},
                kabbage = {},
                pznPageData = {}
              }
            } = await ajax.get(requestUrl, { requestName: 'app-request-pzn' });

            setCardMemberData(cardMemberData);
            triggerColorChoicesUpdate();
            setPznData({
              offerList: pznData,
              pznAttributes,
              dcf,
              kabbage,
              pznPageData,
              eepUsedForPznReq: EEP.replace(/\//g, '')
            });
            const kabbageDataIsPopulated = Object.keys(kabbage).length > 0;
            const isHomePage = pageId === pznPageIds.businessHome;
            const userCardsExist = !!get(cardMemberData, 'userCards.length');
            const includeKabbage =
              kabbageDataIsPopulated && isHomePage && userCardsExist;
            if ((pznData.length || includeKabbage) && needToFirePznExt()) {
              requestPznAnalytics({
                pageId,
                pznData,
                pznAttributes,
                eepUsed: eepForPzn,
                pznCardId,
                pmcCode,
                includeKabbage,
                needCmIndicator: determineCmIndicator(cardMemberData)
              });
            }
          } catch (errorObj) {
            if (__PROD__) {
              setPznErrInfo(errorObj);
            }
            setCardMemberData();
          }
        };

        fetchPznData();
      }
    },
    [hasPznDataArrived, hasLegacyPznRequestDispatched]
  );

  return (
    <React.Fragment>
      <SetCardMemberClassName />
      {shouldRenderOfferExpiry && <OfferExpiryModal />}
    </React.Fragment>
  );
};

FetchPznData.propTypes = {
  pageId: PropTypes.string,
  cardShortName: PropTypes.string,
  pmcCode: PropTypes.string,
  routeEEP: PropTypes.string,
  globalData: PropTypes.object,
  setCardMemberData: PropTypes.func,
  setPznData: PropTypes.func,
  setPznErrInfo: PropTypes.func,
  usePZN: PropTypes.bool,
  useCM: PropTypes.bool,
  shouldRenderOfferExpiry: PropTypes.bool,
  hasLegacyPznRequestDispatched: PropTypes.bool,
  hasPznDataArrived: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  const routeEepVal =
    ownProps.pageId === 'open_vac'
      ? getVacPathParts(ownProps.params).eep || ''
      : get(ownProps, 'params.eep');

  return {
    routeEEP: routeEepVal || '',
    hasLegacyPznRequestDispatched: getHasLegacyPznRequestDispatched(state),
    hasPznDataArrived: hasPznDataArrived(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setCardMemberData: data => dispatchSetCardMemberDataAction(dispatch, data),
  setPznData: data => dispatchSetPznDataAction(dispatch, data),
  setPznErrInfo: errObj => dispatchSetPznError(dispatch, errObj),
  triggerColorChoicesUpdate: () => dispatchUpdateFromSessStore(dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FetchPznData)
);
