import { createContext } from 'react';
import { createSelector } from 'reselect';
import { getExperimentalPageData, getPageCardsData } from 'src/universal/redux/page';
import {
  hasOneXpRequestFinished as getHasOneXpRequestFinished,
  getOneXpExperiments
} from 'src/universal/redux/oneXp';
import { getPageFeatureFlags } from 'src/universal/redux/page';
import {
  isProspect as getIsProspect,
  isLoggedOut as getIsLoggedOut
} from 'src/universal/redux/userType';

export const getG147ModalContent = createSelector(
  getExperimentalPageData,
  ({ g147 }) => {
    return g147?.modal;
  }
);

export const getG147SideRailContent = createSelector(
  [getExperimentalPageData, getPageCardsData],
  ({ g147 }, cardData) => {
    return {
      ...g147?.leftRail,
      shortName: cardData[0].shortName
    };
  }
);

export const getG147OfferBox = createSelector(
  getExperimentalPageData,
  ({ g147 }) => {
    return g147?.offerBox;
  }
);

export const DynamicAppModalContext = createContext(null);

export const getShouldUseDynamicAppFlow = createSelector(
  [
    getHasOneXpRequestFinished,
    getOneXpExperiments,
    getPageFeatureFlags,
    getIsProspect,
    getIsLoggedOut
  ],
  (
    hasOneXpRequestFinished,
    oneXpExperiments,
    { useDynamicAppFlow },
    isProspect,
    isLoggedOut
  ) => {
    if (!useDynamicAppFlow) {
      return {
        hideApplyButton: false,
        dynamicAppFlow: false
      };
    }

    if (!hasOneXpRequestFinished) {
      return {
        hideApplyButton: true,
        dyanmicAppFlow: false
      };
    }

    const G147 = oneXpExperiments['GCS-147-DynamicApp'];

    if (!G147) {
      return {
        hideApplyButton: false,
        dynamicAppFlow: false
      };
    }

    const useDynamicApp = ['variantB', 'variantA'].includes(
      G147.dimensions[0].variant
    );
    return {
      hideApplyButton: false,
      dynamicAppFlow: useDynamicApp && (isProspect || isLoggedOut)
    };
  }
);
