import textRemoveTags from 'service_modules/utils/text-remove-tags';
import { pageReqTypes } from '../meta-tags';

// TODO: Reduce complexity so this linter rule can be re-enabled
/* eslint-disable complexity */
export default function(pageType, pageData, pageComponents) {
  const schemaTypeValues = {};

  switch (pageType) {
    case pageReqTypes.US_BUSINESS_HOME: {
      schemaTypeValues.schemaTypeSomeProducts = [];
      const { schema = {} } = (pageData && pageData.pageInfo) || {};
      const pageMenu = (pageComponents && pageComponents.pageMenu) || [];

      pageMenu.map(section => {
        if (section.type) {
          const schemaMeta = schema[section.type];
          const url = (schemaMeta && schemaMeta.url) || '';
          const updatedSection = Object.assign({}, section, { url: url });
          schemaTypeValues.schemaTypeSomeProducts.push(updatedSection);
        }
      });

      const articleHeader =
        (pageComponents &&
          pageComponents.pageTopHeader &&
          pageComponents.pageTopHeader.header) ||
        '';
      schemaTypeValues.schemaTypeArticle = { header: textRemoveTags(articleHeader) };
      break;
    }
    case pageReqTypes.US_BUSINESS_VAC: {
      const metaData =
        (pageData && pageData.pageInfo && pageData.pageInfo.metaData) || {};
      const cards = pageData.cards;

      schemaTypeValues.schemaTypeListItems =
        cards &&
        cards.map(card => {
          const shortName = card.shortName;
          const cardMetaData = metaData[shortName];
          const title =
            (cardMetaData && textRemoveTags(cardMetaData.schemaName)) || '';
          const description =
            (cardMetaData && textRemoveTags(cardMetaData.schemaDescription)) || '';
          const url = (cardMetaData && cardMetaData.url) || '';
          const imageDescription =
            (cardMetaData && textRemoveTags(cardMetaData.imageDescription)) || '';
          const imageContentUrl =
            (cardMetaData && textRemoveTags(cardMetaData.imageContentUrl)) || '';
          return {
            description,
            title,
            url,
            shortName,
            imageDescription,
            imageContentUrl
          };
        });

      schemaTypeValues.schemaTypeArticle = {
        header:
          (pageData && pageData.hero && textRemoveTags(pageData.hero.header)) || ''
      };
      break;
    }
    case pageReqTypes.US_CORP_VAC: {
      const metaData = pageData && pageData.pageInfo && pageData.pageInfo.metaData;
      const cards = pageComponents;
      schemaTypeValues.schemaTypeSomeProducts =
        cards &&
        cards.map(card => {
          const shortName = card.shortName;
          const cardMetaData = metaData[shortName];
          const title =
            (cardMetaData && textRemoveTags(cardMetaData.schemaName)) || '';
          const description =
            (cardMetaData && textRemoveTags(cardMetaData.schemaDescription)) || '';
          const url = (card && card.learnMore && card.learnMore.url) || '';

          return { description, title, url };
        });

      const pageDataObj = pageData && pageData.pageData;
      schemaTypeValues.schemaTypeArticle = {
        header:
          (pageDataObj &&
            pageDataObj.hero &&
            textRemoveTags(pageDataObj.hero.header)) ||
          ''
      };
      break;
    }
    case pageReqTypes.US_BUSINESS_COMPARE: {
      const preselectedCards = (pageData && pageData.preselectedCards) || 'default';
      const preselectedCardGroup =
        preselectedCards &&
        pageData.preselectedCompare &&
        pageData.preselectedCompare[preselectedCards];
      const preselectedCardNames =
        preselectedCardGroup && preselectedCardGroup.cards;

      schemaTypeValues.schemaTypeListItems =
        preselectedCardNames &&
        preselectedCardNames.map(card => {
          const schemaObj =
            pageData &&
            pageData.pageInfo &&
            pageData.pageInfo.schema &&
            pageData.pageInfo.schema[card];
          const title = (schemaObj && textRemoveTags(schemaObj.schemaName)) || '';
          const description =
            (schemaObj && textRemoveTags(schemaObj.schemaDescription)) || '';
          const url = (schemaObj && schemaObj.url) || '';

          return { description, title, url };
        });
      break;
    }
  }

  return schemaTypeValues;
}
/* eslint-enable complexity */
