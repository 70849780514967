import { findByKeyValue } from 'core_modules/utils/array.utils.js';
import { pageReqTypes } from '../meta-tags';

// TODO: Reduce complexity so this linter rule can be re-enabled
/* eslint-disable complexity */

export default function(pageType, pageComponents, globalData, pageData) {
  const imageUrls = {};

  const leftMenus =
    globalData &&
    globalData.globalHeaderPage &&
    globalData.globalHeaderPage.leftMenus;
  const logoValues = leftMenus && findByKeyValue(leftMenus, 'type', 'logo', {});
  imageUrls.logo = logoValues && logoValues.imageUrl;
  imageUrls.image = imageUrls.logo;

  switch (pageType) {
    case pageReqTypes.US_CORP_PDP:
    case pageReqTypes.US_BUSINESS_PDP: {
      const sourceObj = pageComponents || pageData;
      imageUrls.image =
        sourceObj && sourceObj.appAssets && sourceObj.appAssets.cardArt;
      break;
    }
    case pageReqTypes.US_BUSINESS_HOME: {
      const heroImages = pageComponents && pageComponents.heroImages;
      imageUrls.articleImage =
        (heroImages &&
          heroImages['combo-desktop'] &&
          heroImages['combo-desktop'].url) ||
        '';
      break;
    }
    case pageReqTypes.US_BUSINESS_VAC:
      imageUrls.articleImage =
        (pageData && pageData.benefits && pageData.benefits.imageUrl) || '';
      break;
    case pageReqTypes.US_CORP_VAC: {
      const hero = pageData && pageData.pageData && pageData.pageData.hero;
      imageUrls.articleImage =
        (hero && hero.imageUrl && hero.imageUrl.desktop) || '';
      break;
    }
  }

  return imageUrls;
}
/* eslint-enable complexity */
