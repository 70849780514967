import { getStateObj } from 'src/universal/utils/reduxStoreUtil';
import { getActivePageName } from 'src/universal/redux/page';
import { determineCmIndicator } from 'src/universal/components/PZN/FetchData/getPznParams';

export const needToFirePznExt = () => {
  /*
  GACC-8932: for CM mode, we do NOT trigger the regular pzn-extension call
  for all pages except SB-PDP, SB-VAC and SB-Compare
  DE475212: we need to enable pzn.EXT call for b2b home page
  */
  const pagesAllowPznExtForCardMember = [
    'businessPDP',
    'businessCardsV2',
    'compareCards',
    'businessHome',
    'businessHub'
  ];
  const stateObj = getStateObj() || {};
  const cmInfo = stateObj.cardMemberInfo || {};

  if (determineCmIndicator(cmInfo)) {
    const currentPageName = getActivePageName(stateObj) || '';
    if (!pagesAllowPznExtForCardMember.includes(currentPageName)) {
      return false;
    }
  }
  return true;
};

export const needToFireDcfExt = () => {
  /*
  GACC-8932: no DCF ext call for all pages for card member.
  */
  const stateObj = getStateObj() || {};
  const cmInfo = stateObj.cardMemberInfo || {};
  if (determineCmIndicator(cmInfo)) {
    return false;
  }
  return true;
};
