import { pageReqTypes } from '../meta-tags';

// TODO: Reduce complexity so this linter rule can be re-enabled
/* eslint-disable complexity */
export default function(pageType, pageComponents, metaData, pageData) {
  let pageMetaData;

  switch (pageType) {
    case pageReqTypes.US_CORP_PDP:
    case pageReqTypes.US_BUSINESS_PDP: {
      const sourceObj = pageComponents || pageData;
      const shortName = sourceObj && sourceObj.shortName;
      pageMetaData =
        (shortName && metaData && metaData[shortName]) ||
        (metaData && metaData.default);
      break;
    }
    case pageReqTypes.US_BUSINESS_COMPARE: {
      const preselectedCards = (pageData && pageData.preselectedCards) || null;
      pageMetaData =
        (preselectedCards && metaData && metaData[preselectedCards]) ||
        (metaData && metaData.default);
      break;
    }
    default:
      // bc, cc, bhp
      pageMetaData = metaData && metaData.default;
  }

  return pageMetaData;
}
/* eslint-enable complexity */
