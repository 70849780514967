import React from 'react';
import textRemoveTags from 'service_modules/utils/text-remove-tags';
import { pageReqTypes } from '../meta-tags';

// TODO: Reduce complexity so this linter rule can be re-enabled
/* eslint-disable complexity */
function schemaTypeProduct(pageType, seo, schemaTagValues) {
  const { context = '', type = '', brandType = '', brandName = '' } = seo || {};
  const imageUrls = schemaTagValues && schemaTagValues.imageUrls;
  const image = imageUrls && imageUrls.image;
  const logo = imageUrls && imageUrls.logo;
  const includeImage = image && logo && image !== logo;
  const pageTitle = schemaTagValues && textRemoveTags(schemaTagValues.pageTitle);
  const title = schemaTagValues && textRemoveTags(schemaTagValues.ogTitle);
  const schemaName = schemaTagValues && textRemoveTags(schemaTagValues.schemaName);
  const schemaDescription =
    schemaTagValues && textRemoveTags(schemaTagValues.schemaDescription);
  const canonicalHref = schemaTagValues && schemaTagValues.canonicalHref;

  return (
    <script type="application/ld+json" key={pageTitle}>
      {`{
        "@context": "${context}",
        "@type": "${type}",
        "name": "${pageType === pageReqTypes.US_BUSINESS_PDP ? title : schemaName}",
        ${includeImage ? `"image": "${image}",` : ''}
        "description": "${schemaDescription}",
        "url": "${canonicalHref}",
        "brand": {
          "@type": "${brandType}",
          "name": "${brandName}",
          "logo": "${logo}"
        }
      }`}
    </script>
  );
}
/* eslint-enable complexity */

function schemaTypeSomeProduct(pageType, seo, schemaTypeSomeProducts) {
  const context = seo && seo.context;
  const type = seo && seo.subType;

  const schemas =
    schemaTypeSomeProducts &&
    schemaTypeSomeProducts.map((someProduct, index) => (
      <script type="application/ld+json" key={index}>
        {`{
          "@context": "${context}",
          "@type": "${type}",
          "description": "${textRemoveTags(someProduct.description) || ''}",
          "name": "${textRemoveTags(someProduct.title) || ''}",
          "url": "${someProduct.url}"
        }`}
      </script>
    ));

  return schemas;
}

function schemaTypeWPHeader(pageType, seo, schemaTagValues) {
  const { context = '' } = seo || {};
  const headline = (schemaTagValues && schemaTagValues.pageTitle) || '';
  const schemaDescription =
    schemaTagValues && textRemoveTags(schemaTagValues.schemaDescription);

  return (
    <script type="application/ld+json" key={headline}>
      {`{
        "@context": "${context}",
        "@type": "WPHeader",
        "headline": "${headline}",
        "description": "${schemaDescription}"
      }`}
    </script>
  );
}

function schemaTypeItemList(pageType, seo, schemaTagValues, schemaTypeListItems) {
  const context = seo && seo.context;
  const type = schemaTagValues.type || (seo && seo.subType);
  const schemas = (
    <script type="application/ld+json" key={schemaTagValues.pageTitle}>
      {`{
          "@context": "${context}",
          "@type": "${type}",
          "name": "${schemaTagValues.pageTitle || ''}",
          "url": "${schemaTagValues.canonicalHref || ''}",
          "description": "${schemaTagValues.pageDescription || ''}",
          "itemListOrder": "${schemaTagValues.itemListOrder || ''}",
          "numberOfItems": ${schemaTypeListItems ? schemaTypeListItems.length : 0},
          "itemListElement": ${
            schemaTypeListItems
              ? `[
            ${schemaTypeListItems.map((someItem, i) => {
              const imageBlock = someItem.imageContentUrl
                ? `,
                    "image":  {
                      "@context": "${context}",
                      "@type": "ImageObject",
                      "contentUrl": "${someItem.imageContentUrl}",
                      "width": 177,
                      "height": 112,
                      "description": "${someItem.imageDescription}"
                    }`
                : '';
              return `{
                  "@type": "${schemaTagValues.childrenType}",
                  "position": ${i + 1},
                  "url": "${someItem.url}",
                  "name": "${textRemoveTags(someItem.title) || ''}"${imageBlock}
                }`;
            })}]`
              : `[]`
          }
      }`}
    </script>
  );
  return schemas;
}

// TODO: Reduce complexity so this linter rule can be re-enabled
/* eslint-disable no-unused-vars, complexity */
function schemaTypeArticle(pageType, seo, schemaTagValues, schemaTypeValues) {
  const context = (seo && seo.context) || '';
  const type = (seo && seo.articleType) || '';
  const publisherType = (seo && seo.publisherType) || '';
  const publisherName = (seo && seo.publisherName) || '';
  const header =
    schemaTypeValues &&
    schemaTypeValues.schemaTypeArticle &&
    textRemoveTags(schemaTypeValues.schemaTypeArticle.header);
  const canonicalHref = schemaTagValues && schemaTagValues.canonicalHref;
  const articleImage =
    schemaTagValues &&
    schemaTagValues.imageUrls &&
    schemaTagValues.imageUrls.articleImage;

  return (
    <script type="application/ld+json" key={header}>
      {`{
        "@context": "${context}",
        "@type": "${type}",
        "headline": "${header}",
        "url": "${canonicalHref}",
        "image": "${articleImage}",
        "publisher": {
          "@type": "${publisherType}",
          "name": "${publisherName}"
        }
      }`}
    </script>
  );
}

function formatItemListElementSchema(itemListElement) {
  const array = itemListElement.map(
    item => `{
    "@type": "${item.type}",
    "position": "${item.position}",
    "item": {
      ${
        item.item.type
          ? `"@type": "${item.item.type}",
      `
          : ''
      }"@id": "${item.item.id}",
      "name": "${item.item.name}"
    }
  }`
  );

  return `${array}`;
}

function formatMainEntitySchema(mainEntity) {
  const array = mainEntity.map(
    item => `{
    "@type": "${item.type}",
    "name": "${item.name}",
    "acceptedAnswer": {
      "@type": "${item.acceptedAnswer.type}",
      "text": "${item.acceptedAnswer.text}"
    }
  }`
  );

  return `[${array}]`;
}

function schemaBreadcrumbsList(breadcrumbSchema, index) {
  return (
    <script type="application/ld+json" key={index}>
      {`{
       "@context": "${breadcrumbSchema.context}",
       "@type": "${breadcrumbSchema.type}",
       "itemListElement": ${
         breadcrumbSchema.itemListElement
           ? `[${formatItemListElementSchema(breadcrumbSchema.itemListElement)}]`
           : `[]`
       }
      }`}
    </script>
  );
}

function schemaFaqPage(faqPageSchema, index) {
  return (
    <script type="application/ld+json" key={index}>
      {`{
       "@context": "${faqPageSchema.context}",
       "@type": "${faqPageSchema.type}",
       "mainEntity": ${
         faqPageSchema.mainEntity
           ? formatMainEntitySchema(faqPageSchema.mainEntity)
           : `[]`
       }
      }`}
    </script>
  );
}

function getSchemaList(metaData) {
  const { schema } = metaData || [];

  return schema.map((eachSchema, index) => {
    if (eachSchema.type === 'BreadcrumbList') {
      return schemaBreadcrumbsList(eachSchema, index);
    }
    if (eachSchema.type === 'FAQPage') {
      return schemaFaqPage(eachSchema, index);
    }
  });
}

/* eslint-disable max-params */
export default function(
  pageType,
  seo,
  schemaTagValues,
  schemaTypeValues,
  vacFilter,
  activeFilter,
  metaDataList,
) {
  let schemas = <meta />;
  let schemaTypeListItems =
    (schemaTypeValues && schemaTypeValues.schemaTypeListItems) || [];

  switch (pageType) {
    case pageReqTypes.US_CORP_PDP:
    case pageReqTypes.US_BUSINESS_PDP:
      schemas = schemaTypeProduct(pageType, seo, schemaTagValues);
      break;
    case pageReqTypes.US_BUSINESS_HOME:
    case pageReqTypes.US_CORP_VAC:
      schemas = [schemaTypeProduct(pageType, seo, schemaTagValues)].concat(
        // TODO: research the SEO requirements for the body of 'Article' schema type,
        //       then possibly enable 'Article' by uncommenting schemaTypeArticle() below
        // schemaTypeArticle(pageType, seo, schemaTagValues, schemaTypeValues)
        schemaTypeSomeProduct(
          pageType,
          seo,
          schemaTypeValues && schemaTypeValues.schemaTypeSomeProducts
        )
      );
      break;
    case pageReqTypes.US_BUSINESS_VAC: {
      const vacFilterContent = vacFilter && vacFilter.content;

      if (activeFilter && vacFilterContent) {
        const selectedCards = vacFilterContent.find(c => c.filter === activeFilter)
          .cards;

        if (selectedCards) {
          schemaTypeListItems = schemaTypeListItems.filter(card =>
            selectedCards.includes(card.shortName)
          );
        }
      }
    }

    /* eslint-disable no-fallthrough */
    case pageReqTypes.US_BUSINESS_COMPARE: {
      let schemaList;
      schemas = [
        schemaTypeWPHeader(pageType, seo, schemaTagValues),
        schemaTypeItemList(pageType, seo, schemaTagValues, schemaTypeListItems)
      ];

      if (activeFilter && Array.isArray(metaDataList) && metaDataList.length > 0) {
        const activeFilterMetaData = metaDataList.filter(
          metaData => metaData.key === activeFilter
        );
        if (activeFilterMetaData.length > 0) {
          schemaList = getSchemaList(activeFilterMetaData[0]);
        }
      }

      schemaList && schemas.push(schemaList);

      break;
    }
  }

  return schemas;
}
