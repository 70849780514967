import { setPageAttributes } from 'service_modules/models/dtm-tagging';
import removeTags from 'service_modules/utils/text-remove-tags';

/*
US4885279:
(1) this module fulfills the requirement of populating key info from pzn
    request to the data node of "digitalData.page.attributes".
(2) it collects/formats the values from pzn data per specified in the story.  
(3) it then populates the value to the designated field.
(4) this module must be invoked prior to triggering the dtm page-load event.
*/
export const populateEligibleEepTaggings = offerList => {
  const list = Array.isArray(offerList) ? offerList : [];
  const arrVals = list.reduce((accumulator, offer) => {
    const headerVal = removeTags(String(offer.header || '')).replace(/,/g, '');
    const descVal = removeTags(String(offer.description || '')).replace(/,/g, '');
    const unitVal = `${offer.iaCode || ''}|${offer.eep ||
      ''}|${headerVal}|${descVal}`;
    accumulator.push(unitVal.slice(0, 255));
    return accumulator;
  }, []);

  setPageAttributes({ intlinkimp: arrVals.join(',') });
};
