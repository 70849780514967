import get from 'lodash.get';
import { isBrowser } from 'acq-utils/lib/misc';

export const trackExperimentImpression = experiment => {
  if (isBrowser() && window.trackIt && experiment && experiment.id) {
    if (experiment.dimensions.length <= 1) {
      const payload = {
        dimension: get(experiment, 'dimensions[0].name'),
        experimentName: experiment.name
      };
      window.trackIt.impressExperimentDimension(payload);
      Array.isArray(window.oneXpEvents) &&
        window.oneXpEvents.push({ ...payload, impressed: true });
    } else {
      experiment.dimensions.forEach(element => {
        const payload = {
          dimension: element.name,
          experimentName: experiment.name
        };
        window.trackIt.impressExperimentDimension(payload);
        Array.isArray(window.oneXpEvents) &&
          window.oneXpEvents.push({ ...payload, impressed: true });
      });
    }
  }
};
export const trackExperimentMetric = payload => {
  if (isBrowser() && window.trackIt && payload) {
    new Promise(resolve => {
      resolve(window.trackIt.trackEvent(payload));
    }).then(
      data =>
        Array.isArray(window.oneXpEvents) &&
        window.oneXpEvents.push(data.nextDataLayer)
    );
  }
};
