import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import metaTagValues from './utils/meta-tag-values';
import PropTypes from 'prop-types';
import { getActiveFilter } from 'src/universal/redux/cardsFilter';

export const pageReqTypes = {
  US_BUSINESS_VAC: 'bc',
  US_BUSINESS_HOME: 'bhp',
  US_BUSINESS_COMPARE: 'bcc',
  US_BUSINESS_PDP: 'bcd',
  US_CORP_VAC: 'cc',
  US_CORP_PDP: 'corppdp',
  NOT_PROVIDED: undefined
};

const propRequestTypes = [
  pageReqTypes.US_BUSINESS_COMPARE,
  pageReqTypes.US_BUSINESS_HOME,
  pageReqTypes.US_BUSINESS_PDP,
  pageReqTypes.US_BUSINESS_VAC,
  pageReqTypes.US_CORP_PDP,
  pageReqTypes.US_CORP_VAC,
  pageReqTypes.NOT_PROVIDED
];

const MetaTags = props => {
  const {
    pageTitle,
    pageDescription,
    ogTitle,
    ogDescription,
    pageKeywords,
    canonicalHref,
    hrefLang,
    locale,
    type,
    siteName,
    image,
    site,
    card,
    creator,
    schemas
  } = metaTagValues(props);
  return (
    <Helmet>
      <html lang={locale && locale.replace('_', '-')} />
      <title>{pageTitle}</title>
      <link rel="canonical" href={canonicalHref} />
      {hrefLang}
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />

      <meta property="og:locale" content={locale} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:url" content={canonicalHref} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content={card} />
      <meta name="twitter:site" content={site} />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={ogDescription} />
      <meta name="twitter:creator" content={creator} />
      <meta name="twitter:image" content={image} />

      {schemas}
    </Helmet>
  );
};

MetaTags.propTypes = {
  pageType: PropTypes.oneOf(propRequestTypes),
  pageData: PropTypes.object,
  globalData: PropTypes.object,
  pageComponents: PropTypes.object
};

const mapStateToProps = state => ({
  activeFilter: getActiveFilter(state)
});

export default connect(mapStateToProps)(MetaTags);
