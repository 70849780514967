import React from 'react';
import { pageReqTypes } from '../meta-tags';

function getHrefLangTags(hrefLang) {
  if (hrefLang === null || typeof hrefLang !== 'object') {
    return [];
  }

  const hrefLangTags = [];

  for (const prop in hrefLang) {
    if (hrefLang.hasOwnProperty(prop)) {
      const tag = (
        <link rel="alternate" hrefLang={prop} href={hrefLang[prop]} key={prop} />
      );
      hrefLangTags.push(tag);
    }
  }

  return hrefLangTags;
}

// TODO: Reduce complexity so this linter rule can be re-enabled
/* eslint-disable complexity */
export default function(pageType, pageData, pageComponents) {
  let hrefLangTags = [];

  switch (pageType) {
    case pageReqTypes.US_CORP_PDP:
    case pageReqTypes.US_BUSINESS_PDP: {
      const sourceObj = pageComponents || pageData;
      const shortName = sourceObj && sourceObj.shortName;
      const hrefLang =
        shortName &&
        pageData &&
        pageData.pageInfo &&
        pageData.pageInfo.hrefLang &&
        pageData.pageInfo.hrefLang[shortName];
      hrefLangTags = getHrefLangTags(hrefLang);
      break;
    }
    case pageReqTypes.US_BUSINESS_COMPARE: {
      const preselectedCards = (pageData && pageData.preselectedCards) || 'default';
      const hrefLang =
        preselectedCards &&
        pageData &&
        pageData.pageInfo &&
        pageData.pageInfo.hrefLang &&
        pageData.pageInfo.hrefLang[preselectedCards];
      hrefLangTags = getHrefLangTags(hrefLang);
      break;
    }
    default: {
      const hrefLang = pageData && pageData.pageInfo && pageData.pageInfo.hrefLang;
      hrefLangTags = getHrefLangTags(hrefLang);
      break;
    }
  }

  return hrefLangTags;
}
/* eslint-enable complexity */
