import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FocusTrap } from '@americanexpress/aqx-components';
import { ModalMgrContext } from './modalMgrContext';
const modelIndex = {};

export const toggleModalByName = name => {
  if (modelIndex[name]) {
    return modelIndex[name]();
  }

  console.warn(`${name} - Modal Name Not Found`);
};

export const ModalComponent = React.memo(
  ({ children, qeId, isModalOpen, toggle, styles, skipFocusTrap = false, name }) => {
    const wrapEle = useRef(null);
    useLayoutEffect(
      () => {
        if (isModalOpen) {
          document.body.classList.add('modalOpen');

          if (!skipFocusTrap) {
            setTimeout(() => {
              if (name === 'CORP-PDP-LEADGEN' && wrapEle.current) {
                const targetEle = wrapEle.current.querySelector('form select');
                if (targetEle && targetEle.focus) {
                  targetEle.focus();
                  return;
                }
              }

              const eleList = wrapEle.current
                ? wrapEle.current.querySelectorAll('a, button')
                : null;
              if (eleList && eleList.length > 0) {
                eleList[eleList.length - 1].focus();
              }
            }, 800);
          }
        }

        return () => {
          document.body.classList.remove('modalOpen');
        };
      },
      [isModalOpen]
    );

    return (
      <div
        data-qe-id={qeId}
        className={clsx(styles.root, {
          [styles.modalOpen]: isModalOpen
        })}
      >
        <div className={styles.overlay} onClick={toggle} />
        <div className={styles.contentContainer}>
          {skipFocusTrap && (
            <ModalMgrContext.Provider value={{ isModalOpen: isModalOpen }}>
              <div ref={wrapEle} className={styles.content}>
                {children}
              </div>
            </ModalMgrContext.Provider>
          )}
          {!skipFocusTrap && (
            <FocusTrap useAriaModal={true}>
              <div ref={wrapEle} className={styles.content}>
                {children}
              </div>
            </FocusTrap>
          )}
        </div>
      </div>
    );
  }
);

/**
 * Modal - standard modal with the ability to toggle itself without redux
 * @param {string} name - Modal name used for toggling the modal via toggleModalByName
 * @param {node} children - React Children
 * @param {string} qeId - qeId used for E2E testing
 * @param {object[strings]} styles - styles object to pass in
 */
const ModalManager = React.memo(
  ({ name, children, qeId = 'modal', styles, skipFocusTrap }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = useCallback(
      () => setIsModalOpen(lastModalState => !lastModalState),
      []
    );

    // we need to forcibly reset modal toggle everytime otherwise it may get out of sync on csrs
    modelIndex[name] = toggle;

    return (
      <ModalComponent
        isModalOpen={isModalOpen}
        toggle={toggle}
        qeId={qeId}
        styles={styles}
        skipFocusTrap={skipFocusTrap}
        name={name}
      >
        {children}
      </ModalComponent>
    );
  }
);

ModalManager.propTypes = {
  name: PropTypes.string.isRequired,
  qeId: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
  skipFocusTrap: PropTypes.bool
};

export default ModalManager;
