import get from 'lodash.get';
import textRemoveTags from 'service_modules/utils/text-remove-tags';
import getHrefLang from './href-lang';
import getImageUrls from './image-urls';
import getPageComponents from './page-components';
import getPageMetaData from './page-metadata';
import getSchemaTypeValues from './schema-type-values';
import schemasLdJson from './schema-ld-json';
import { omit } from 'lodash';

function metaObjects(props) {
  const pageType = props && props.pageType;
  const pageData =
    props && Object.keys(props.pageData).length > 0 ? props.pageData : props.page;
  const globalData = props && props.globalData;

  const pageComponents = getPageComponents(pageType, props, pageData);

  const seo = globalData && globalData.seo;
  const metaData = pageData && pageData.pageInfo && pageData.pageInfo.metaData;
  const pageMetaData = getPageMetaData(pageType, pageComponents, metaData, pageData);
  const imageUrls = getImageUrls(pageType, pageComponents, globalData, pageData);
  const schemaTypeValues = getSchemaTypeValues(pageType, pageData, pageComponents);
  const hrefLang = getHrefLang(pageType, pageData, pageComponents);

  const { metaDataList } = pageData && pageData.pageInfo;
  var activeFilter = props && props.activeFilter;
  const vacFilter = pageData && pageData.vacFilter;
  // for some reason on server side activeFilter is always all card so we need to fix that
  if (!(typeof window === 'object')){ //if on serverside
    const urlPathArray = props.currentURL.split('/').filter(Boolean);
    const filters = vacFilter.content.map(c => c.filter);
    activeFilter = urlPathArray.find(value => filters.includes(value)) || 'all-cards';
  }
  const filterMetaData =
    activeFilter &&
    metaDataList &&
    omit(metaDataList.find(m => m.key === activeFilter), 'key');

  return {
    pageType,
    hrefLang,
    pageMetaData: filterMetaData
      ? { ...pageMetaData, ...filterMetaData }
      : pageMetaData,
    seo,
    imageUrls,
    schemaTypeValues,
    vacFilter,
    activeFilter
  };
}

function titles(pageType, pageMetaData) {
  const pageTitle = (pageMetaData && textRemoveTags(pageMetaData.metaTitle)) || '';
  const ogTitle = (pageMetaData && textRemoveTags(pageMetaData.title)) || pageTitle;
  const schemaName =
    (pageMetaData &&
      textRemoveTags(pageMetaData.schemaName || pageMetaData.metaTitle)) ||
    '';

  return { pageTitle, ogTitle, schemaName };
}

function descriptions(pageType, pageMetaData) {
  const pageDescription =
    (pageMetaData && textRemoveTags(pageMetaData.metaDescription)) || '';
  const ogDescription =
    (pageMetaData && textRemoveTags(pageMetaData.description)) || pageDescription;
  const schemaDescription =
    (pageMetaData &&
      textRemoveTags(
        pageMetaData.schemaDescription || pageMetaData.metaDescription
      )) ||
    '';

  return { pageDescription, ogDescription, schemaDescription };
}

// TODO: Reduce complexity so this linter rule can be re-enabled
/* eslint-disable complexity */
export default function(props) {
  const {
    pageType,
    hrefLang,
    pageMetaData,
    seo,
    imageUrls,
    schemaTypeValues,
    vacFilter,
    activeFilter
  } = metaObjects(props);
  const { pageTitle, ogTitle, schemaName } = titles(pageType, pageMetaData);
  const { pageDescription, ogDescription, schemaDescription } = descriptions(
    pageType,
    pageMetaData
  );
  const pageKeywords = (pageMetaData && pageMetaData.metaKeyword) || '';
  const canonicalHref = (pageMetaData && pageMetaData.relCanonical) || '';

  const locale = (seo && seo.locale) || '';
  const type = (seo && seo.type) || '';
  const siteName = (seo && seo.site_name) || '';
  const image =
    (pageMetaData && pageMetaData.ogImage) || (imageUrls && imageUrls.image) || '';

  const site = (seo && seo.site) || '';
  const card = (seo && seo.card) || '';
  const creator = (seo && seo.creator) || '';
  const currentURL = props && props.currentURL;
  const schemaTagValues = {
    pageTitle,
    ogTitle,
    schemaName,
    pageDescription,
    schemaDescription,
    canonicalHref,
    imageUrls,
    itemListOrder: (pageMetaData && pageMetaData.itemListOrder) || '',
    type: (pageMetaData && pageMetaData.type) || '',
    childrenType: (pageMetaData && pageMetaData.childrenType) || ''
  };
  const metaList = props && Object.keys(props.pageData).length > 0 ? props.pageData.pageInfo?.metaDataList : props.page.pageInfo.metaDataList;
  const schemas = schemasLdJson(
    pageType,
    seo,
    schemaTagValues,
    schemaTypeValues,
    vacFilter,
    activeFilter,
    metaList,
    currentURL
  );

  return {
    pageTitle,
    pageDescription,
    ogTitle,
    ogDescription,
    pageKeywords,
    canonicalHref,
    hrefLang,
    locale,
    type,
    siteName,
    image,
    site,
    card,
    creator,
    schemas
  };
}
/* eslint-enable complexity */
