const isOfferFromPzn = (offerObj = {}) => {
  return (
    !offerObj.defaultOffer &&
    !offerObj.offSiteEEP &&
    !offerObj.preQual &&
    !offerObj.srcCodeInducedDefaultEep
  );
};

export default isOfferFromPzn;
