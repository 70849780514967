export default class PznExpiryMonitor {
  constructor({
    interval = 5000,
    minutesBeforeExpiration = 20,
    expiryHandler = () => ({})
  }) {
    this.interval = interval;
    this.minutesBeforeExpiration = minutesBeforeExpiration;
    this.expiryHandler = expiryHandler;
    this.intervalHandle = null;
    this.baseDate = new Date();
  }

  hasExpired() {
    const diffMilliSec = new Date().getTime() - this.baseDate.getTime();
    const minVal = Math.round(((diffMilliSec % 86400000) % 3600000) / 60000);
    return minVal >= this.minutesBeforeExpiration;
  }

  monitor = domEvent => {
    const eleObj = domEvent.target;
    if (eleObj && String(eleObj.tagName).toLowerCase() === 'a' && eleObj.href) {
      this.baseDate = new Date();
    }
  };

  setup() {
    this.baseDate = new Date();
    document.body.addEventListener('click', this.monitor);
  }

  cleanUp() {
    document.body.removeEventListener('click', this.monitor);
  }

  start() {
    const self = this;
    self.setup();

    self.intervalHandle = window.setInterval(() => {
      if (self.hasExpired()) {
        self.stop();
        self.expiryHandler();
      }
    }, self.interval);
  }

  stop() {
    if (this.intervalHandle) {
      window.clearInterval(this.intervalHandle);
    }
    this.cleanUp();
  }
}
