export const determinePznEEP = (globalData, eepVal) => {
  /*
  GACC-8689: some cleanup for Orchestra: we no longer support CM-EEP logics.
  */
  const DEFAULT_PROSPECT_EEP = '45094';
  const globalHeader = globalData ? globalData.globalHeaderPage || {} : {};
  const defaultEEP = globalHeader.defaultEEP || DEFAULT_PROSPECT_EEP;

  if (
    typeof eepVal === 'string' &&
    !isNaN(eepVal) &&
    eepVal.length > 0 &&
    eepVal !== defaultEEP
  ) {
    return eepVal;
  }

  return defaultEEP;
};
