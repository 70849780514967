import { getApplyButtonObj } from 'service_modules/models/shared/cross-model-helper';
import { createDtmAndTrackActionHandler } from 'src/universal/utils/analyticsTracking';
import { toggleModalByName } from 'src/universal/components/Modal';
import { triggerCmPznClickEvent } from 'src/universal/utils/orchestraCmPznClickEvent';

// this creates a handler by combining offerGating and analytics tracking
export const createCombinedApplyHandler = ({
  linkDef,
  card = {},
  pagePznId,
  appendToActionAttr,
  cmPznEventInfo = {},
  modalName = null
}) => {
  return event => {
    const evt = event || window.event;
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    createDtmAndTrackActionHandler({
      dtmObj: linkDef.dtm,
      actionTracking: {
        ...(linkDef.actionTracking || {}),
        postpone: true
      },
      appendToActionAttr: appendToActionAttr
    })(evt);

    if ((linkDef.type || '') === 'MODAL') {
      toggleModalByName(modalName || 'APPLY');
      if (cmPznEventInfo.needClickEvent) {
        triggerCmPznClickEvent({
          offerEep: cmPznEventInfo.offerEep,
          iaCode: cmPznEventInfo.iaCode
        });
      }
    } else {
      getApplyButtonObj(linkDef, card, pagePznId, true).onClick(evt);
    }
  };
};
