import browserStorage from 'acq-utils/lib/BrowserStorage';
import { STORAGE } from 'acq-utils/lib/enums';
import { isBrowser } from 'acq-utils/lib/misc';

const SESSKEY = 'offer-expired';
const storage = browserStorage(STORAGE.session).bindItem(SESSKEY);

export const setOfferExpired = (expired = true) => {
  if (isBrowser()) {
    storage.set(`${expired ? 'yes' : 'no'}`);
  }
};

export const getOfferExpired = () => {
  const rawVal = isBrowser() ? storage.get() : 'no';
  return rawVal === 'yes';
};
