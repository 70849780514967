import {
  sendDTMClickEvent,
  sendDTMEvents as sendDTMEventsFunc
} from 'service_modules/models/dtm-tagging';
import parseArrayToObject from 'src/universal/utils/parseArrayToObject';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';

/**
 * Used for sending multiple DTM events at once
 * @param {array[Object]} - Array of dtm event objects
 */
export const sendDTMEvents = ({ dtmList: dtmObjArray }) => {
  if (!dtmObjArray || !Array.isArray(dtmObjArray)) {
    console.error('sendDTMEvents - dtmList Not Valid', { dtmObjArray });
    dtmObjArray = [];
  }

  sendDTMEventsFunc(dtmObjArray);
};

/**
 * Used for sending a single DTM event
 * @param {Object} - dtm event object
 */
export const sendDTMEvent = ({ dtmObj }) => {
  if (!dtmObj) {
    console.error('sendDTMEvent - dtmObj Not Valid', { dtmObj });
    dtmObj = {};
  }

  sendDTMClickEvent(dtmObj);
};

export const createDtmHandler = ({ dtmObj, cb }) => () => {
  if (!dtmObj) {
    console.error('createDtmHandler - dtmObj Not Valid', { dtmObj });
    dtmObj = {};
  }

  sendDTMEvent({ dtmObj });

  if (typeof cb === 'function') {
    cb();
  }
};

export const createDtmAndTrackActionHandler = ({ dtmObj, cb }) => () =>
  createDtmHandler({ dtmObj, cb })();

/**
 * @param {Object} dtm - dtm object, example below, must contain eventAsset with a descriptor var
   "dtm": {
      "eventAsset": "MerchantFundedOffer:${descriptor}",
      "sections": [
        {
          "identifier": "prev",
          "text": "previous"
        }
      ]
    }
 * @param {string} sectionsIdentifier - sections.identifier key, text for that key will replace descriptor
 * @param {string} defaultText - default text if sectionsIdentifier is not found
 * @returns {Object} dtm object
 * @example getDtmObjectWithDynamicVar(aboveDtmObject, 'prev')  
    returns
      "dtm": {
        "eventAsset": "MerchantFundedOffer:previous",
      }
 */
export const getDtmObjectWithDynamicVar = (
  dtm,
  sectionsIdentifier,
  defaultText = ''
) => {
  const dtmObj = dtm || {};
  const sections = parseArrayToObject(dtmObj.sections, 'identifier') || {};
  return {
    ...dtmObj,
    ...(dtm
      ? {
          eventAsset: replaceStringWithVars({
            varsObject: {
              descriptor: (sections[sectionsIdentifier] || {}).text || defaultText
            },
            string: dtmObj.eventAsset || ''
          })
        }
      : {})
  };
};
