'use strict';

export function removeAt(collection, startIndex, endIndex) {
  if (typeof startIndex === 'undefined') {
    return collection;
  }
  endIndex = typeof endIndex === 'undefined' ? startIndex : endIndex;
  return collection.splice(startIndex, endIndex - startIndex + 1);
}

export function hasIndexOf(collection, item) {
  return Array.isArray(collection) && collection.indexOf(item) !== -1;
}

export function removeItem(collection, item) {
  return Array.isArray(collection) && removeAt(collection, collection.indexOf(item));
}

export function addOrRemove(collection, item) {
  if (hasIndexOf(collection, item)) {
    removeItem(collection, item);
    return 'removed';
  }
  collection.push(item);
  return 'added';
}

export function arrayWrap(property, force) {
  if (!property && !force) {
    return [];
  }
  return Array.isArray(property) ? property : [property];
}

export function unique(collection) {
  const a = collection.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) {
        a.splice(j--, 1);
      }
    }
  }
  return a;
}

// collection = [{key:value},{key:value}]
export function findByKeyValue(collection, key, value, defaultVal) {
  if (!collection || !Array.isArray(collection)) {
    return defaultVal;
  }
  const len = collection.length;
  for (let i = 0; i < len; i++) {
    if (collection[i][key] === value) {
      return collection[i];
    }
  }
  return defaultVal;
}
