import React from 'react';
import MainContainer from 'page_modules/business-page-containers/templates/main-container';
import ProgressCircle from 'core_modules/ajax-loaders/templates/progress-circle/progress-circle';
import PageErrorBoundary from 'src/universal/components/PageErrorBoundary';

import usePageDisplayNameClass from 'src/universal/hooks/usePageDisplayNameClass';
import { PageMeta } from 'acq-components/lib/utils/pageMeta.js';
import MetaTags, { pageReqTypes } from 'core_modules/meta-tags/meta-tags';
import FetchPznData from 'src/universal/components/PZN/FetchData';
import { getPznIdByPageName } from 'src/universal/components/PZN/config';

export const PageWrapper = ({
  children = <div />,
  data = {},
  displayName = '',
  FetchPageDataHelper = {},
  mainContainerProps = {},
  shouldRenderOfferExpiry,
  pznPageId,
  usePZN,
  cardShortName,
  pmcCode = '',
  seoData,
  fetchPznRequest = true,
  currentURL
}) => {
  if (!FetchPageDataHelper.didLoadingFinish(data, cardShortName)) {
    return <ProgressCircle />;
  }
  const { global = {}, page = {}, pageData = {}, globalData = {} } = data;
  const { seo: seoPageData = {} } = page;
  const filledInGlobal =
    typeof global === 'object' && Object.keys(global).length !== 0
      ? global
      : globalData;

  //US3840149: we need to accommodate the legacy VAC arrangement for now
  const tmpPageData = { cards: data.cards, ...page }; //need to add the cards back into the page objeect for metaTags
  const metaComponent =
    pznPageId === 'open_vac' ? (
      <MetaTags
        pageType={pageReqTypes.US_BUSINESS_VAC}
        page={tmpPageData}
        pageData={pageData}
        globalData={filledInGlobal}
        currentURL={currentURL}
      />
    ) : (
      <PageMeta {...seoData || seoPageData} />
    );

  usePageDisplayNameClass(displayName);
  pznPageId = pznPageId || getPznIdByPageName(FetchPageDataHelper.pageName);
  return (
    <PageErrorBoundary pageData={page}>
      {metaComponent}
      <MainContainer {...mainContainerProps}>
        {children}
        {fetchPznRequest && (
          <FetchPznData
            pageId={pznPageId}
            usePZN={usePZN}
            globalData={global}
            shouldRenderOfferExpiry={shouldRenderOfferExpiry}
            cardShortName={cardShortName}
            pmcCode={pmcCode}
          />
        )}
      </MainContainer>
    </PageErrorBoundary>
  );
};
