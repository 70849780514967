import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import { store } from 'src/services/store/store';
import { pznPageIds } from 'src/universal/components/PZN/config';

/*
What does this module do?
(1) This module is basically using the existing pzn extention call to fire
    the CM-PZN clickEvent to our BE. 
(2) The end-point behavior is described in this story "GACC-8732".
(3) This method sends out request in this format:
    /us/cardshop-api/api/v1/open/content/pznExtn/open_vac/45094?isCmOfferClickEvent=true
(4) the method is only used for the "Apply Now" CTAs for Card-Member only.
    This is because this use-case does not use the clickTrackingUrl provided by
    the CM-PZN server due to the fact that we must go thru the offer-gating route.  
    This calling was suggested by CM-PZN team (Mina).
(5) Why not using the existing pzn-ext method? 
    (src/universal/components/PZN/FetchData/requestPznAnalytics.js)
    Because that module has been overloaded with too many parameters what are 
    not needed for this use-case.  
*/

export const triggerCmPznClickEvent = async ({
  pageId = '',
  offerEep = '',
  iaCode = ''
}) => {
  // activePageName
  let pznPgId;
  if (!pageId) {
    const stateObj =
      typeof store !== 'undefined' && store.getState ? store.getState() : {};
    pznPgId = pznPageIds[stateObj.activePageName] || 'unknown';
  } else {
    pznPgId = pageId;
  }

  const requestUrl = `open/content/pznExtn/${pznPgId}/${offerEep}?isCmOfferClickEvent=true&iaCode=${iaCode}`;
  await ajax.get(requestUrl, { requestName: 'app-request-pzn-ext' });
};
