import get from 'lodash.get';
import queryString from 'query-string';
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';
import { createCombinedApplyHandler } from 'src/universal/utils/applyOfferGatingHandler';
import { getStateObj } from 'src/universal/utils/reduxStoreUtil';
import { getPageCardsData, getPageFeatureFlags } from 'src/universal/redux/page';

/*
This module encapsulates major parts of the following key logics:
(1) if user is a GCS Member and if "pznClickTrackUrl" is present:
      - for RequestAnUpgrade CTAs, we build the cardApp url into the pznClickTrackUrl
        and then directly plant the url onto "a" (anchor) element. We do not go thru offer-gating process

(2) if user is a GCS Member and if "pznClickTrackUrl" is not present, then
      - for RequestAnUpgrade CTAs, we directly use the static cardApp url on "a" element.

(3) all "Apply Now" CTAs, both Prospect and CM, will continue to use Offer-Gating mechanism.
*/
export const buildUpdatedCtaUrl = (
  pznClickTrackingUrl = '',
  linkObj = {},
  allowPreEmbedding = false
) => {
  /*
    input:  pznClickTrackUrl and an linkObj
    output: a URL string.
  */
  // intlink search param has alrady been appended
  let linkUrl = linkObj.url || '';

  if (typeof pznClickTrackingUrl !== 'string' || pznClickTrackingUrl.length <= 0) {
    return linkUrl;
  }

  /*
  we need to guard against 2 situations
  (1) the clickTrakingUrl has built-in locationURL as part of query-param.
      we need to return it back as is.
  (2) incoming url has been pre-encoded. if so, we are to decode it first.
  */

  if (
    allowPreEmbedding === true &&
    pznClickTrackingUrl.indexOf('locationURL=http') > -1
  ) {
    return pznClickTrackingUrl;
  }

  if (linkUrl.indexOf('%3A%2F%2F') > -1) {
    linkUrl = decodeURIComponent(linkUrl);
  }

  const parsedObj = queryString.parseUrl(pznClickTrackingUrl);
  parsedObj.query.locationURL = linkUrl;
  const resultUrl = `${parsedObj.url}?${queryString.stringify(parsedObj.query)}`;
  return resultUrl;
};

export const showCmUpgrade = () => {
  const featureFlags = getPageFeatureFlags(getStateObj()) || {};
  return !!featureFlags.showCmUpgrade;
};

export const getCmUpgradeEep = shortName => {
  if (!showCmUpgrade()) {
    return null;
  }
  const state = getStateObj();
  const cardList = getPageCardsData(state) || [];
  const targetCard = cardList.find(c => c.shortName === shortName);
  const upgradeEepVal = targetCard
    ? targetCard.upgradeEep || get(targetCard, 'card.upgradeEep')
    : null;
  return upgradeEepVal;
};

/* eslint-disable complexity */
export const getUpgradeCtaProps = (
  ctaParent = {},
  pznOffer = {},
  isGcsMember = false
) => {
  const isPznUpgradeCard = !!get(pznOffer, 'isUpgradeCard');
  const isDefaultOffer = !!get(pznOffer, 'defaultOffer');
  const upgradeBtn = ctaParent.upgradeButton || ctaParent.requestAnUpgrade || {};
  const isUpgradeCtaDefPresent = !!upgradeBtn.text;
  const hasUpgradeEep = !!getCmUpgradeEep((pznOffer || {}).shortName);
  const needUpgradeCta =
    isGcsMember &&
    isUpgradeCtaDefPresent &&
    isPznUpgradeCard &&
    hasUpgradeEep &&
    showCmUpgrade();
  const pznClickTrackingUrl = get(pznOffer, 'clickTrackingUrl');

  const cmPznEventInfo = {
    needClickEvent: !isDefaultOffer && isGcsMember,
    offerEep: get(pznOffer, 'eep'),
    iaCode: get(pznOffer, 'iaCode')
  };

  const isPznTrackingUrlPresent =
    typeof pznClickTrackingUrl === 'string' && pznClickTrackingUrl.length > 0;

  if (
    needUpgradeCta &&
    isPznTrackingUrlPresent &&
    !upgradeBtn.doneBuildingClickTrackingUrl
  ) {
    const urlVal = upgradeBtn.url || '';
    if (urlVal.length > 0 && urlVal.indexOf('?') < 0) {
      const srcDtm = upgradeBtn.dtmTag || upgradeBtn.dtm || {};
      const dtmCopy = {
        ...srcDtm,
        type: srcDtm.type
      };
      upgradeBtn.url = mapUrlWithDtmLinkQuery(urlVal, dtmCopy);
    }
    upgradeBtn.url = buildUpdatedCtaUrl(pznClickTrackingUrl, upgradeBtn);
    upgradeBtn.originalUrl = urlVal;
    upgradeBtn.doneBuildingClickTrackingUrl = true;
  }

  return {
    upgradeBtn,
    needUpgradeCta,
    cmPznEventInfo
  };
};

/*
what does this func do?
(1) this func serves 2 displays for VAC and Compare. Both are using legacy data structure
(2) for "prospect" use-case, we do exactly the same as before: we go thru offer-gating process.
(3) for "card-member" use-case, we apply item(1)(2) stated at the line-9 of this module.
*/
export const determineCardMemberCta = (cardObj = {}, isGcsMember) => {
  const { upgradeBtn, needUpgradeCta, cmPznEventInfo } = getUpgradeCtaProps(
    cardObj,
    cardObj?.pznOffer,
    isGcsMember
  );

  const assetVal =
    get(upgradeBtn, 'dtmTag.eventAsset') || get(upgradeBtn, 'dtm.eventAsset') || '';

  return {
    upgradeBtn,
    needUpgradeCta,
    upgradeEventAsset: assetVal,
    cmPznEventInfo
  };
};

/*
what does this func do?
(1) this func serves 2 displays: PDP.reinforcementBanner and PDP.stickyBanner. Both are using recent/non-legacy data structure
(2) for both locations, the resulting values will be either ApplyNow CTA or RequestAnUpgrade CTA.
(3) for "prospect" use-case, we do exactly the same as before: we go thru offer-gating process.
(4) for "card-member" use-case, we apply item(1)(2) stated at the line-9 of this module.
*/
export const buildDynamicCtaProps = ({
  cardObj,
  links,
  applyCtaKey,
  dtmLocation,
  isGcsMember,
  pagePznId
}) => {
  const { upgradeBtn, needUpgradeCta, cmPznEventInfo } = getUpgradeCtaProps(
    links,
    cardObj.pznOffer,
    isGcsMember
  );

  const applyBtn = links[applyCtaKey];
  const upgradeEventAsset = get(upgradeBtn, 'dtm.eventAsset') || '';
  upgradeBtn.dtm = {
    ...(upgradeBtn.dtm || {}),
    eventAsset: `${upgradeEventAsset}${dtmLocation}`
  };

  const btnToUse = needUpgradeCta ? upgradeBtn : applyBtn || {};
  const hasCta = !!get(btnToUse, 'text');

  const ctaUrl = needUpgradeCta ? upgradeBtn.url : applyBtn.url;

  const dtmAsset = get(btnToUse, 'dtm.eventAsset') || {};
  const linkDef = needUpgradeCta
    ? { ...btnToUse, url: btnToUse.originalUrl }
    : {
        ...btnToUse,
        dtm: {
          ...btnToUse.dtm,
          eventAsset:
            applyCtaKey === 'applyOnline' ? `${dtmAsset}_${dtmLocation}` : dtmAsset
        }
      };

  const applyClick = createCombinedApplyHandler({
    linkDef,
    card: cardObj,
    pagePznId,
    cmPznEventInfo
  });

  const ctaClickHandler = needUpgradeCta
    ? () => {
        sendDTMClickEvent(btnToUse.dtm);
      }
    : applyClick;

  return {
    hasCta,
    ctaObj: btnToUse,
    ctaUrl,
    ctaClickHandler
  };
};

export const getUpgradeEepForLink = (linkObj, pznOffer) => {
  // we only process linkObj with certain keys
  let resultEep;

  const itemsNeedStaticUpgradeEep = [
    'requestAnUpgrade',
    'offerTermsUpgrade',
    'ratesFeesUpgrade'
  ];

  const isUpgradeCard = pznOffer.isUpgradeCard;
  if (itemsNeedStaticUpgradeEep.includes(linkObj.key || '') && isUpgradeCard) {
    const upgradeEep = getCmUpgradeEep(pznOffer.shortName);
    if (upgradeEep) {
      resultEep = upgradeEep;
    }
  }

  return resultEep;
};

// just a temp func for development purpose
export const getPznCmMockDataParam = () => {
  if (typeof window === 'undefined') {
    return;
  }

  return window.location.href.indexOf('useMockCmPgData=y') > -1
    ? 'useMockCmPgData=y'
    : '';
};
