import { useEffect } from 'react';

const usePageDisplayNameClass = (displayName, prevDisplayName) => {
  useEffect(() => {
    if (prevDisplayName) {
      document.body.classList.remove(prevDisplayName);
    }

    document.body.classList.add(displayName);

    return () => {
      document.body.classList.remove(displayName);
    };
  }, []);

  return null;
};

export default usePageDisplayNameClass;
