import React from 'react';
import SSRErrorPage from 'page_modules/http-errors/ssr-error-page';

class PageErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <SSRErrorPage
          httpStatus={500}
          pageData={this.props.pageData}
          reqUrl={location.pathname}
          errorDetails={this.state.error.toString()}
          stackTrace={this.state.errorInfo.componentStack}
        />
      );
    }

    return this.props.children;
  }
}

export default PageErrorBoundary;
