import { useRef, useEffect } from 'react';
import get from 'lodash.get';

import {
  removeIntLinkImp,
  dtmDependentScripts,
  buildPznProductInfo,
  getSpecialOffersEventAsset
} from 'service_modules/utils/dtm-utils';
import {
  setAnalyticsCardMemberData,
  invokePageLoadEvent,
  invokeRicherOfferEvent
} from 'service_modules/models/dtm-tagging';
import { populateEligibleEepTaggings } from 'src/universal/utils/eligibleEepTagging';

export const dispatchDtmActions = ({
  FetchPageDataHelper,
  dispatch,
  data,
  cardShortName: cardName,
  dtmCategoryPaths = [],
  isCardMember,
  cardTitles,
  isUserLoggedIn,
  hasCMInfoArrived,
  usePZN = false,
  pznData = {}
}) => {
  const hasDtmPageLoadBeenCalled = useRef(false);
  const hasDtmPrepBeenDone = useRef(false);

  const pageLoadDtmPrep = () => {
    const pageName = get(data, dtmCategoryPaths[0]);
    const subCategory2 = dtmCategoryPaths[1]
      ? get(data, dtmCategoryPaths[1])
      : undefined;
    const subCategory3 = dtmCategoryPaths[2]
      ? get(data, dtmCategoryPaths[2])
      : undefined;

    FetchPageDataHelper.dispatchDtmDataReady(pageName, subCategory2, subCategory3);
    hasDtmPrepBeenDone.current = true;
  };

  if (
    FetchPageDataHelper.didLoadingFinish(data, cardName) &&
    !hasDtmPrepBeenDone.current
  ) {
    pageLoadDtmPrep();
  }

  const isPDP = () => {
    return get(data, dtmCategoryPaths[2]) === 'CardDetail';
  };

  const pageLoadDtmExecute = () => {
    if (usePZN) {
      if (pznData.pznDataArrived && !hasDtmPageLoadBeenCalled.current) {
        hasDtmPageLoadBeenCalled.current = true;
        const cardList = data.cards || get(data, 'page.cards') || [];
        const pznOfferAttributes = pznData.pznAttributes || {};
        const productInfoList = buildPznProductInfo({
          offerList: pznData.pznOfferList,
          cardList,
          lineOfBusiness: pznOfferAttributes.lineOfBussiness
        });

        if (isPDP()) {
          const pdpEventAsset = getSpecialOffersEventAsset({
            offerList: pznData.pznOfferList,
            cardList
          });

          if (pdpEventAsset) {
            invokeRicherOfferEvent({ pdpEventAsset });
          }
        }

        populateEligibleEepTaggings(pznData.pznOfferList);

        invokePageLoadEvent({
          pznAttributes: pznOfferAttributes,
          productInfoList,
          viewDispatch: dispatch
        });
      }
    } else {
      if (!hasDtmPageLoadBeenCalled.current) {
        hasDtmPageLoadBeenCalled.current = true;
        FetchPageDataHelper.dispatchDtmPageLoad(dispatch);
      }
    }
  };

  const afterMainDataArrival = () => {
    if (isCardMember) {
      if (hasCMInfoArrived) {
        setAnalyticsCardMemberData(isUserLoggedIn, cardTitles);
        pageLoadDtmExecute();
      }
    } else {
      pageLoadDtmExecute();
    }
  };

  useEffect(
    () => {
      removeIntLinkImp();
      dtmDependentScripts();

      if (!FetchPageDataHelper.didLoadingFinish(data, cardName)) {
        dispatch(FetchPageDataHelper.fetchDataCSR({ params: { cardName } })).then(
          () => afterMainDataArrival()
        );
      } else {
        afterMainDataArrival();
      }
    },
    [isCardMember, hasCMInfoArrived, pznData.pznDataArrived]
  );

  return null;
};
