import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isCardMemberCardsLoaded,
  isCPSMemberOnly,
  isGCSMember,
  hasMemberInfoArrived,
  isOtherMemberType
} from 'src/universal/redux/cardMemberInfo';
import { isLoggedOutCPS, isCardMember } from 'src/universal/redux/userType';

const SetCardMemberClassName = ({
  isCardMemberCardsLoaded,
  isCPS,
  isLoggedOutCPS,
  isCardMember,
  isGCS,
  isOtherType,
  hasMemberInfoArrived
}) => {
  const cardMemberClass = 'is-card-member-data-loaded';
  const cpsMemberClass = 'is-cps-member';
  const allClasses = [cardMemberClass, cpsMemberClass];

  const addClass = className => {
    document.body.classList.add(className);
  };

  const removeClass = className => {
    return () => {
      document.body.classList.remove(className);
    };
  };

  useEffect(
    () => {
      if (isCardMemberCardsLoaded) {
        addClass(cardMemberClass);
        return () => {
          return removeClass(cardMemberClass);
        };
      }
    },
    [isCardMemberCardsLoaded]
  );

  useEffect(
    () => {
      if (isCPS || isLoggedOutCPS || isOtherType) {
        addClass(cpsMemberClass);
        return () => {
          return removeClass(cpsMemberClass);
        };
      }
    },
    [isCPS, isOtherType, isLoggedOutCPS, isCardMember, isGCS, hasMemberInfoArrived]
  );

  useEffect(() => {
    return () => {
      document.body.classList.remove(...allClasses);
    };
  }, []);

  return null;
};

SetCardMemberClassName.propTypes = {
  isCardMemberCardsLoaded: PropTypes.bool,
  isCPS: PropTypes.bool
};

const mapStateToProps = state => ({
  isCardMemberCardsLoaded: isCardMemberCardsLoaded(state),
  isCPS: isCPSMemberOnly(state),
  isLoggedOutCPS: isLoggedOutCPS(state),
  isCardMember: isCardMember(state),
  isGCS: isGCSMember(state),
  isOtherType: isOtherMemberType(state),
  hasMemberInfoArrived: hasMemberInfoArrived(state)
});

export default connect(mapStateToProps)(SetCardMemberClassName);
